// src/directives/lazyLoadDirective.js
export default {
  mounted(el, binding) {
    const loadImage = () => {
      el.src = binding.value;
    };

    const options = {
      root: null,
      rootMargin: "200px",
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          loadImage();
          observer.unobserve(el);
        }
      });
    }, options);

    if ("IntersectionObserver" in window) {
      observer.observe(el);
    } else {
      // Fallback for browsers that do not support IntersectionObserver
      loadImage();
    }

    // Additional fallback for Safari
    setTimeout(() => {
      if (!el.complete || !el.naturalHeight) {
        loadImage();
      }
    }, 1000); // Adjust the delay as needed
  },
};
