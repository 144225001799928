<template>
  <component
    :is="as"
    @click="$emit('clickBtn')"
    class="flex cursor-pointer relative items-center justify-center gap-4 rounded-[30px] max-md:px-5"
    :class="buttonStyle"
  >
    <img
      v-if="icon"
      :src="require('@/assets/icons/svg/' + icon)"
      :alt="text"
      class="max-w-full !block shrink-0 overflow-hidden object-contain object-center"
      :class="width"
    />
    <p
      v-show="showText"
      class="self-center font-medium leading-5"
      :class="type === 'outlined' ? 'text-mainBlack-1000' : 'text-white'"
    >
      {{ text }}
    </p>
    <p v-if="hasCounter && counterNumber" class="counter bg-primary-1000">
      {{ counterNumber }}
    </p>
  </component>
</template>

<script setup>
import { computed, defineProps } from "vue";

const props = defineProps({
  as: {
    type: String,
    default: "button",
    validator: (value) => ["router-link", "button", "a"].includes(value),
  },
  type: {
    type: String,
    default: "mainBlack",
    validator: (value) => ["mainBlack", "primary", "outlined"].includes(value),
  },
  icon: {
    type: String,
  },
  showText: {
    type: Boolean,
    default: true,
  },
  classList: {
    type: [String],
    default: "text-[14px]",
  },
  hasCounter: {
    type: Boolean,
    default: false,
  },
  counterNumber: {
    type: [String, Number],
    default: "",
  },
  paddingStyle: {
    type: [String],
    default: "px-9 py-[1.125rem]",
  },
  text: {
    type: String,
    default: "button",
  },
  width: {
    type: String,
    default: "w-4",
  },
});

const buttonStyle = computed(() => {
  switch (props.type) {
    case "mainBlack":
      return "bg-mainBlack-1000 " + props.classList + " " + props.paddingStyle;
    case "primary":
      return "bg-primary-1000 " + props.classList + " " + props.paddingStyle;
    case "secondary":
      return "bg-secondary-1000 " + props.classList + " " + props.paddingStyle;
    case "outlined":
      return (
        "bg-white border border-black shadow-[0px_0px_0px_2px_rgba(0,0,0,0.2)] " +
        props.classList +
        " " +
        props.paddingStyle
      );
    default:
      return "bg-mainBlack-1000 " + props.classList + " " + props.paddingStyle;
  }
});
</script>

<style lang="scss" scoped>
.counter {
  position: absolute;
  color: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -7px;
  right: -7px;
}
</style>
